export type ButtonType = "submit" | "button";

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface Props extends ButtonProps {}

export function ButtonBase(props: Props) {
  const className = `${props.className || ""} focus:outline-none`;
  // HACK (jake): change default from submit to button
  const type = props.type || "button";

  return <button {...props} className={className} type={type} />;
}
