export function invariant(value: any, message: string) {
  if (value == null) {
    const error = new Error(message);
    error.name = "Invariant Violation";

    (error as any).framesToPop = 1;

    throw error;
  }

  return value;
}
