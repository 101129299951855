import { ButtonBase } from "./ButtonBase";

type ButtonSize = "small" | "medium";
type ButtonColor = "davys" | "cultured";

type Props = Omit<React.ComponentProps<typeof ButtonBase>, "className"> & {
  color: ButtonColor;
  border: boolean;
  size: ButtonSize;
  disconnect?: boolean;
  fullWidth?: boolean;
};

export function Button(props: Props) {
  const {
    color,
    border,
    size,
    disabled,
    disconnect,
    fullWidth,
    ...rest
  } = props;

  const classes = [
    "font-bold active:relative active:top-[1px] focus-visible:border-pink",
    disabled || disconnect ? "cursor-not-allowed" : "cursor-pointer",
    fullWidth ? "w-full" : "",
    getColorClasses(color, !!disabled),
    getBorderClasses(border, color, !!disabled),
    getSizeClasses(size),
  ].join(" ");

  return (
    <ButtonBase
      {...rest}
      disabled={disabled || disconnect}
      className={classes}
    />
  );
}

function getSizeClasses(size: ButtonSize) {
  switch (size) {
    case "small":
      return "h-10 px-6 rounded-lg";

    case "medium":
      return "h-12 px-6 rounded-lg text-lg";
  }
}

function getColorClasses(color: ButtonColor, disabled: boolean) {
  if (disabled) {
    return "bg-alabaster text-davys";
  }

  switch (color) {
    case "davys":
      return "bg-davys text-cultured";

    case "cultured":
      return "bg-cultured text-davys";
  }
}

function getBorderClasses(
  border: boolean,
  color: ButtonColor,
  disabled: boolean
) {
  const base = "border-4";

  if (disabled) return `${base} border-alabaster`;

  switch (color) {
    case "davys":
      return `${base} ${border ? "border-cultured" : "border-davys"}`;

    case "cultured":
      return `${base} ${border ? "border-davys" : "border-cultured"}`;
  }
}
