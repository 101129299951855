import { invariant } from "universal/utils/invariant";

export const config = {
  BASE_URL: invariant(
    getBaseUrl() || `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`,
    "NEXT_PUBLIC_VERCEL_URL must be set if NEXT_PUBLIC_BASE_URL is falsy"
  ),
  GA_TRACKING_ID: invariant(
    process.env.NEXT_PUBLIC_GA_TRACKING_ID,
    "NEXT_PUBLIC_GA_TRACKING_ID must be set"
  ),
  IS_SERVER: process.env.NEXT_IS_SERVER === "true",
  SENTRY_DSN: invariant(
    process.env.NEXT_PUBLIC_SENTRY_DSN,
    "NEXT_PUBLIC_SENTRY_DSN must be set"
  ),
  SENTRY_ENABLED: process.env.NEXT_PUBLIC_SENTRY_ENABLED === "true",
  SENTRY_ENVIRONMENT: invariant(
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    "NEXT_PUBLIC_SENTRY_ENVIRONMENT must be set"
  ),
  SENTRY_SERVER_ROOT_DIR: invariant(
    process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
    "NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR must be set"
  ),
  STRIPE_PUBLISHABLE_KEY: invariant(
    process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    "NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY must be set"
  ),
  TWITTER_BRAND_USER_ID: "948493988897554433",
  VERCEL_GIT_COMMIT_SHA: invariant(
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    "NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA must be set"
  ),
};

function getBaseUrl() {
  return invariant(
    process.env.NEXT_PUBLIC_BASE_URL,
    "NEXT_PUBLIC_BASE_URL must be set"
  );
}
