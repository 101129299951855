import { VicariouslyError } from "universal/lib/errors";
import { Nullable } from "universal/types/utils";
import { sentry, init as sentryInit } from "universal/vendor/sentry";

export function init() {
  // sentryInit();
}

export function setUserId(userIdOrNull: Nullable<string>) {
  // const user = userIdOrNull ? { id: userIdOrNull } : null;
  // sentry.setUser(user);
}

export function captureException(exception: any) {
  // if (exception instanceof VicariouslyError) {
  //   sentry.captureException(exception, { extra: exception.extra });
  //   return;
  // }
  // sentry.captureException(exception);
}

export function flush(timeout: number) {
  // return sentry.flush(timeout);
}
