import React, { createContext, useContext, useState } from "react";
import { Toast } from "./Toast";

interface ToastInput {
  type: "success" | "error";
  body: React.ReactNode;
}

interface RenderedToast extends ToastInput {
  id: string;
}

interface ToastContext {
  toasts: RenderedToast[];
  addToast: (toast: ToastInput) => void;
  removeToast: (id: string) => void;
}

interface Props {
  children: React.ReactNode;
}

const ToastContext = createContext<ToastContext>({
  toasts: [],
  addToast: () => undefined,
  removeToast: () => undefined,
});

const TOAST_DISPLAY_TIMEOUT = 7000;

export function ToastProvider(props: Props) {
  const { children } = props;

  const [toasts, setToasts] = useState<RenderedToast[]>([]);

  const addToast = (input: ToastInput) => {
    const id = `${+new Date()}`;
    const toast = { ...input, id };

    setToasts((state) => [toast, ...state]);
    setTimeout(() => removeToast(id), TOAST_DISPLAY_TIMEOUT);
  };

  const removeToast = (id: string) =>
    setToasts((state) => state.filter((x) => x.id !== id));

  const value = { toasts, addToast, removeToast };

  return (
    <ToastContext.Provider value={value}>
      <div className="fixed z-40 inset-0 bottom-auto pt-6 px-4 pointer-events-none">
        {toasts.map(({ id, type, body }, i) => (
          <div key={id} className={i > 0 ? "mt-8" : ""}>
            <Toast type={type} onClose={() => removeToast(id)}>
              {body}
            </Toast>
          </div>
        ))}
      </div>
      {children}
    </ToastContext.Provider>
  );
}

export const useToasts = (): ToastContext =>
  useContext<ToastContext>(ToastContext);
