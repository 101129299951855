export async function swrFetcher(url: string) {
  const res = await fetch(url);

  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.error);
  }

  return json;
}
