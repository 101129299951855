import { ToastProvider } from "client/components/Toast";
import * as gtag from "client/lib/gtag";
import { swrFetcher } from "client/lib/swrFetcher";
import "client/styles/styles.css";
import "focus-visible";
import { DefaultSeo } from "next-seo";
import Router from "next/router";
import React, { useEffect } from "react";
import { SWRConfig } from "swr";
import { init } from "universal/lib/monitoring";

init();

const SWR_OPTIONS = {
  fetcher: swrFetcher,
};

export default function App({ Component, pageProps, err }) {
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    Router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    <SWRConfig value={SWR_OPTIONS}>
      <ToastProvider>
        <DefaultSeo
          openGraph={{
            images: [
              {
                url: "https://vicariously.io/imgs/og.png",
                width: 1200,
                height: 630,
              },
            ],
          }}
          twitter={{
            handle: "@getvicarious",
            cardType: "summary_large_image",
          }}
        />
        <Component {...pageProps} err={err} />
      </ToastProvider>
    </SWRConfig>
  );
}
