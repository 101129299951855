import { ButtonBase } from "client/components/Button";
import { CloseLine } from "client/components/icons";

type Type = "success" | "error";

interface Props {
  type: Type;
  onClose: () => void;
  children: React.ReactNode;
}

export function Toast(props: Props) {
  const { type, onClose, children } = props;

  const classes = [
    "max-w-md mx-auto p-3 rounded-md shadow-lg text-cultured pointer-events-auto clear-both bounce-in",
    type === "success" ? "bg-success" : "bg-error",
  ].join(" ");

  return (
    <div className={classes}>
      <ButtonBase className="float-right ml-4 mb-4" onClick={onClose}>
        <CloseLine className="w-6 h-6 fill-current" />
      </ButtonBase>
      <span>{children}</span>
    </div>
  );
}
